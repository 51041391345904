import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  getDoctorAppointmentToken(route: string, id: number, getParams?: any) {
    return this.configService.readRequest(route + '/generate-token/' + id, getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getUser(route: string, id: number){
    return this.configService.readRequest(route + '/get-user/' + id)
    .pipe(map(response => {
      return response;
    }));
  }

  getLab(route: string, id: string){
    return this.configService.readRequest(route + '/get-lab/' + id)
    .pipe(map(response => {
      return response;
    }));
  }

}
