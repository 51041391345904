import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionSetService {

  private baseRoute = 'permission-set';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  getPermissionItem(id: number, getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/get-permissible-item/'+id, getParams)
      .pipe(map(response => {
        return response;
      }));
  }

}
