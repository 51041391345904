export class Lab {
    id: number;
    nameEn: string;
    nameAr: string;
    email: string;
    password: string;
    imageEn: string;
    imageAr: string;
    consultationTimeInterval: number;
    maxBookingPerSlot: number;
    homeTestCharge: number;
    adminCommission: number;
    clinicId: number;
    latlon: string;
    stateId: number;
    state: any;
    areaId: number;
    area: any;
    block: string;
    street: string;
    building: string;
    isActive: number;
    createdAt: string;
    updatedAt: string;
    sortOrder: number;
    deeplinkUrl: string;
    bannerEn: string;
    bannerAr: string;
    labServices: [];
    labTests: [];
    labsWorkingDays: [];
    clinic: any;
    labInsurances: [];
    licenseNumber: string;
    licenseExpiryDate: string;
    contractNumber: string;
    contractStartDate: string;
    contractExpiryDate: string;
    adminCommissionType: string;
    isFeatured: number;
    featuredFromDate: string;
    featuredToDate: string;
    facebookLink: string;
    instagramLink: string;
    youtubeLink: string;
    twitterLink: string;
    linkedinLink: string;
    snapchatLink: string;
    tiktokLink: string;
    telephoneNumber: string;
    descriptionEn: string;
    descriptionAr: string;
    exemptFromReservationFee: number;
    butterflyInjection: number;
    femaleNurseRequest: number;
    appointmentType: string;
    contactEmail: string;
    name: string;
    bannerAppEn: string;
    bannerAppAr: string;
    knetPgCharge: string;
    ccPgCharge: string;
    splitPgCharge: string;
    beneficiaryName: string;
    authorizedPersonName: string;
    civilIdNo: string;
    licenseNo: string;
    bankName: string;
    bankAccNo: string;
    iban: string;
    searchKeywords: string;
}