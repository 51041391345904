import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerDataSource } from 'ng2-smart-table';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseRoute = 'product';
  private baseRouteCategory = 'category';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  deleteProductImg(id: number) {
    return this.configService.readRequest(this.baseRoute + '/delete-product-img/' + id, {})
      .pipe(map(response => {
        return response;
      }));
  }

  getAttValueList(id: string) {
    return this.configService.readRequest(this.baseRoute + '/attributes?attribute_set_id=' + id, {})
      .pipe(map(response => {
        return response;
      }));
  }

  productCreateDetails() {
    return this.configService.readRequest(this.baseRoute + '/get-product-option', {})
      .pipe(map(response => {
        return response;
      }));
  }

  createQuickProduct(product: object) {
    return this.configService.postRequest(this.baseRoute + '/quick-product-create', {}, product)
      .pipe(map(response => {
        return response;
      }));
  }

  exportProductExcel(getParams: any) {
    return this.configService.readRequest(this.baseRoute + '/index', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getSortProduct(data) {
    return this.configService.postRequest(this.baseRoute + '/sort/', {}, data)
      .pipe(map(response => {
        return response;
      }));
  }

  toggleApprove(id: number, getParams?: any, postParams?: any) {
    return this.configService.putRequest(this.baseRoute + '/toggle-approve/' + id, getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  updateProductInline(product: object) {
    return this.configService.postRequest(this.baseRoute + '/inline-update/', {}, product)
      .pipe(map(response => {
        return response;
      }));
  }

  randomSort() {
    return this.configService.readRequest(this.baseRoute + '/random-sort', {})
      .pipe(map(response => {
        return response;
      }));
  }

  bulkDeleteProduct(product: object) {
    return this.configService.postRequest(this.baseRoute + '/bulk-product-delete', {}, product)
      .pipe(map(response => {
        return response;
      }));
  }

  generateDeepLink() {
    return this.configService.readRequest(this.baseRoute + '/generate-deeplink', {})
      .pipe(map(response => {
        return response;
      }));
  }

  getSortProductImage(data) {
    return this.configService.postRequest(this.baseRoute + '/image-sort', {}, data)
      .pipe(map(response => {
        return response;
      }));
  }

  getProductByBarcode(barcode: string) {
    return this.configService.readRequest(this.baseRoute + '/product-by-barcode', { barcode: barcode })
      .pipe(map(response => {
        return response;
      }));
  }

  importExcel(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/excel-import', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  importPriceExcel(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/excel-price-import', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  importQuantityExcel(getParams?: any, postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/excel-quantity-import', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }
  
  bulkImageUpload(product: object) {
    return this.configService.postRequest(this.baseRoute + '/bulk-upload', {}, product)
        .pipe(map(response => {
            return response;
        }));
  }
}
