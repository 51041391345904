export class Review {
    id: number;
    comment:string;
    rating: number;
    createDate: string;
    user: any;
    doctor: any;
    lab: any;
    pharmacy: any;
    product: any;
    isApproved: number;
}