export class Doctor {
    id: number;
    nameEn: string;
    nameAr: string;
    email: string;
    password: string;
    yearsExperience: number;
    qualificationEn: string;
    qualificationAr: string;
    titleEn: string;
    titleAr: string;
    image: string;
    gender: string;
    genderName: string;
    type: string;
    typeName: string;
    consultationTimeOnline: number;
    consultationTimeOffline: number;
    clinicId: number;
    consultationPriceRegular: number;
    consultationPriceFinal: number;
    videoConsultationPriceRegular: number;
    videoConsultationPriceFinal: number;
    registrationNumber: string;
    descriptionEn: string;
    descriptionAr: string;
    isActive: number;
    isFeatured: number;
    createdAt: string;
    updatedAt: string;
    licenseNumber: string;
    licenseExpiryDate: string;
    sortOrder: number;
    isDirectory: number;
    deeplinkUrl: string;
    bannerEn: string;
    bannerAr: string;
    doctorStamp: string;
    doctorSignature: string;
    doctorInsurances: [];
    doctorLanguages: [];
    doctorSpecialists: [];
    doctorSymptoms: [];
    doctorWorkingDays: [];
    clinic: any;
    civilId: string;
    contractStartDate: string;
    contractExpiryDate: string;
    contractNumber: string;
    adminCommission: number;
    doctorCornerCommission: number;
    referralCommission: number;
    pharmacyId: number;
    pharmacy: any;
    pharmacyName: string;
    adminCommissionType: string;
    featuredFromDate: string;
    featuredToDate: string;
    facebookLink: string;
    instagramLink: string;
    youtubeLink: string;
    twitterLink: string;
    linkedinLink: string;
    snapchatLink: string;
    tiktokLink: string;
    clinicScopeEn: string;
    clinicScopeAr: string;
    exemptFromReservationFee: number;
    salutation: string;
    latlon: string;
    countryId: number;
    stateId: number;
    areaId: number;
    block: string;
    street: string;
    building: string;
    area: any;
    state: any;
    contactEmail: string;
    bannerAppEn: string;
    bannerAppAr: string;
    knetPgCharge: string;
    ccPgCharge: string;
    splitPgCharge: string;
    beneficiaryName: string;
    authorizedPersonName: string;
    licenseNo: string;
    bankName: string;
    bankAccNo: string;
    iban: string;
    searchKeywords: string;
    bufferTime: number;
}