export class Clinic {
    id: number;
    nameEn: string;
    nameAr: string;
    imageEn: string;
    imageAr: string;
    latlon: string;
    type: string;
    typeName: string;
    countryId: number;
    stateId: number;
    areaId: number;
    block: string;
    street: string;
    building: string;
    email: string;
    password: string;
    isActive: number;
    isFeatured: number;
    featuredFromDate: string;
    featuredToDate: string;
    createdAt: string;
    updatedAt: string;
    sortOrder: number;
    isDirectory: number;
    isProfileHidden: number;
    parentId: number;
    deeplinkUrl: string;
    bannerEn: string;
    bannerAr: string;
    insurances: [];
    specialities: [];
    workingDays: [];
    area: any;
    state: any;
    clinicWorkingDays: [];
    clinicSpecialities: [];
    clinicInsurances: [];
    parentNameEn: string;
    adminCommission: number;
    licenseNumber: string;
    licenseExpiryDate: string;
    contractNumber: string;
    contractStartDate: string;
    contractExpiryDate: string;
    doctors: any;
    clinicImages: [];
    adminCommissionType: string;
    facebookLink: string;
    instagramLink: string;
    youtubeLink: string;
    twitterLink: string;
    linkedinLink: string;
    snapchatLink: string;
    tiktokLink: string;
    telephoneNumber: string;
    whatsappNumber: string;
    descriptionEn: string;
    descriptionAr: string;
    contactEmail: string;
    mazayaTowerId: number;
    isPrivate : number;
    bannerAppEn: string;
    bannerAppAr: string;
    targetId : number;
    beneficiaryName: string;
    authorizedPersonName: string;
    civilIdNo: string;
    licenseNo: string;
    bankName: string;
    bankAccNo: string;
    iban: string;
    searchKeywords: string;
}