export class Banner {
    id: number;
    nameEn: string;
    nameAr: string;
    imageEn: string;
    imageAr: string;
    imageEnApp: string;
    imageArApp: string;
    subTitleEn: string;
    subTitleAr: string;
    linkType: string;
    linkTypeName: string;
    linkId: number;
    url: string;
    isActive: number;
    sortOrder: number;
    type: string;
    typeName: string;
    validFrom: string;
    validTill: string;
    linkIdName: string;
    isOffer: number;
    productId: number;
    product: any;
    homeType: number;
    salamtekExclusive: number;
    permitFileName: string;
    cartRuleId: number;
    subLinkId: number;
    searchKeywords: string;
    descriptionEn: string;
    descriptionAr: string;
}
