import { Injectable } from '@angular/core';
import {ConfigService} from '../config/config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServerDataSource} from 'ng2-smart-table';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private baseRoute = 'category';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  getCategories(getParams?: any) {
    return this.configService.readRequest(this.baseRoute + '/tree', getParams)
      .pipe(map(response => {
        return response;
      }));
  }

  getLeafCategories() {
    return this.configService.readRequest(this.baseRoute + '/leaf-categories', {})
      .pipe(map(response => {
        return response;
      }));
  }
  
  sortCategory(postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/sort', {}, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  removeCategoryImage(postParams?: any) {
    return this.configService.postRequest(this.baseRoute + '/remove-category-image', {}, postParams)
      .pipe(map(response => {
        return response;
      }));
  }
}
