export class Pharmacy {
    id: number;
    nameEn: string;
    nameAr: string;
    email: string;
    password: string;
    imageEn: string;
    imageAr: string;
    minimumOrder: number;
    isFreeDelivery: number;
    isFeatured: number;
    featuredFromDate: string;
    featuredToDate: string;
    shopNumber: number;
    deliveryCharge: number;
    isDriveThru: number;
    adminCommission: number;
    clinicId: number;
    latlon: string;
    stateId: number;
    state: any;
    areaId: number;
    area: any;
    block: string;
    street: string;
    building: string;
    isActive: number;
    deliveredBySalamtek: number;
    createdAt: string;
    updatedAt: string;
    sortOrder: number;
    deeplinkUrl: string;
    bannerEn: string;
    bannerAr: string;
    clinic: any;
    pharmacyWorkingDays: [];
    pharmacyCategoryLinks: any[];
    pharmacyInsurances: [];
    pharmacyDeliveryAreas: [];
    pharmacyDeliveryStates: [];
    deliveryAreaId: any;
    licenseNumber: string;
    licenseExpiryDate: string;
    contractNumber: string;
    contractStartDate: string;
    contractExpiryDate: string;
    adminCommissionType: string;
    isPickupAvailable: string;
    facebookLink: string;
    instagramLink: string;
    youtubeLink: string;
    twitterLink: string;
    linkedinLink: string;
    snapchatLink: string;
    tiktokLink: string;
    telephoneNumber: string;
    whatsappNumber: string;
    deliveryTime: string;
    descriptionEn: string;
    descriptionAr: string;
    contactEmail: string;
    lowStockCount: number;
    bannerAppEn: string;
    bannerAppAr: string;
    knetPgCharge: string;
    ccPgCharge: string;
    splitPgCharge: string;
    beneficiaryName: string;
    authorizedPersonName: string;
    civilIdNo: string;
    licenseNo: string;
    bankName: string;
    bankAccNo: string;
    iban: string;
    searchKeywords: string;
    nameOnInvoice: string;
}